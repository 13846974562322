











































import Vue from 'vue';
import VueNumeric from 'vue-numeric';
import { Component, Prop, Inject } from 'vue-property-decorator';
import { isEmpty } from 'lodash';
import VueTouchKeyboard from 'vue-touch-keyboard';
import { Validator } from 'vee-validate';
import { CustomInput, InputFormatObject, ReturnInput } from './inputTypes';
import { getCurrentLanguage } from '@/translation';
import { namespace } from 'vuex-class';
const AuthenticationNameSpace = namespace('authentication/');

@Component({
  name: 'InputComponentFormat',
  components: {
    'vue-touch-keyboard': VueTouchKeyboard.component,
    VueNumeric,
  },
})
export default class InputComponentFormat extends Vue {
  @Prop({}) inputParam!: any;
  @Prop({}) onlyNumeric: boolean;
  @Inject('$validator') $validator!: Validator;
  @AuthenticationNameSpace.Getter('language') language: string;

  vmodelValue: number | string | null = '';
  emptyValue: boolean = true;
  errorMessage: string = '';
  locale: string = '';

  get separator(): string {
    if (this.language === '' || this.language === 'en') {
      // English locale uses "." as decimal separator and "," as grouping separator
      return ',';
    }
    if (this.language === 'nl') {
      // Dutch locale uses "," as decimal separator and "." as grouping separator
      // However for now we use the english one anyway
      return ',';
    }
    return ',';
  }

  get inputName() {
    return isEmpty(this.inputParam.name) ? ' ' : this.inputParam.name;
  }

  get inputVRule() {
    if (this.inputParam.vRule === null) {
      return ' ';
    }
    return this.inputParam.vRule;
  }

  get errorLabel() {
    const vLabel: string | null =
      this.inputParam.vLabel === undefined ? '' : this.inputParam.vLabel;
    if (isEmpty(vLabel) && isEmpty(this.inputParam.placeHolder)) {
      return '';
    }
    if (isEmpty(vLabel) || vLabel === null) {
      return this.inputPlaceHolder;
    }
    return `${this.$t(vLabel)}`;
  }

  get inputPlaceHolder(): string {
    return isEmpty(this.inputParam.placeHolder)
      ? ''
      : `${this.$t(this.inputParam.placeHolder)}`;
  }

  get inputHasError(): boolean {
    const hasError: boolean =
      this.$validator.errors.has(this.inputParam.name) ||
      this.inputParam.isValid === false;
    this.errorMessage = hasError
      ? this.$validator.errors.first(this.inputParam.name)
      : '';
    return hasError;
  }

  get isInputCorrect(): boolean {
    return (
      this.vmodelValue !== undefined &&
      this.vmodelValue !== null &&
      !this.emptyValue &&
      !this.$validator.errors.has(this.inputParam.name) &&
      !(this.inputParam.isValid === false)
    );
  }

  get inputContainerClass(): string | null {
    return this.inputParam.containerClass === undefined ||
      isEmpty(this.inputParam.containerClass)
      ? 'input-noclass-container'
      : this.inputParam.containerClass;
  }

  get validateClasses(): boolean {
    return (
      this.inputParam.validationClasses === undefined ||
      this.inputParam.validationClasses
    );
  }

  get customClass(): string[] {
    const classes: string[] = [];
    if (this.inputHasError) {
      classes.push('has-error');
    }
    if (this.validateClasses && this.isInputCorrect) {
      classes.push('correct');
    }
    if (this.inputParam.class !== undefined && this.inputParam.class !== null) {
      classes.push(this.inputParam.class);
    }
    return classes;
  }

  get showVLabel(): boolean {
    return (
      !this.inputParam.hideVLabel &&
      this.$validator.errors.has(this.inputParam.name)
    );
  }

  get inputDisabled(): boolean {
    return this.inputParam.disabled === undefined
      ? false
      : this.inputParam.disabled;
  }

  get inputTooltip(): any {
    if (this.errorMessage !== '') {
      return this.errorMessage;
    }
    if (this.inputParam.defaultTooltip) {
      return {
        placement: 'bottom-center',
        content: this.inputPlaceHolder,
      };
    }
    if (
      this.inputParam.tooltip === undefined ||
      this.inputParam.tooltip === null
    ) {
      return null;
    }
    const tooltipContent: string | null = `${this.$t(this.inputParam.tooltip)}`;
    return tooltipContent === null
      ? null
      : { placement: 'bottom-center', content: tooltipContent };
  }

  get enableNegative(): boolean {
    return this.inputParam.showNegative === undefined
      ? true
      : this.inputParam.showNegative;
  }

  get precision(): number {
    return this.inputParam.precision === undefined
      ? 2
      : this.inputParam.precision;
  }

  get currencySymbol(): string {
    if (this.inputParam.showCurrency === false) {
      return '';
    }
    return this.inputParam.currencySymbol === undefined ||
      this.inputParam.currencySymbol === null
      ? '€'
      : this.inputParam.currencySymbol;
  }

  change() {
    const returnInput: ReturnInput = {
      name: this.inputParam.name,
      value: '',
    };
    if (this.vmodelValue !== null && this.vmodelValue !== '') {
      returnInput.value = this.vmodelValue.toString();
    }
    this.$emit('sendInput', returnInput);
  }

  mounted() {
    this.vmodelValue = !this.inputParam.value
      ? ''
      : parseFloat(this.inputParam.value);
    this.emptyValue = this.vmodelValue === '';
  }
}
