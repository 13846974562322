var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ioi-offer-selection-container"},[_c('div',{staticClass:"ioi-offer__title"},[_vm._v(" "+_vm._s(_vm.$t('components.IOIOffer.myOffersTitle'))+" ")]),_c('div',{staticClass:"table-container small-table"},[_c('div',{staticClass:"blue-table  VueTables VueTables--client"},[_c('table',{staticClass:"VueTables__table table is-bordered is-striped is-hoverable is-fullwidth"},[_c('thead',[_c('tr',_vm._l((_vm.columns),function(column){return _c('th',{key:column,staticStyle:{"position":"relative"},attrs:{"tabindex":"0"}},[_c('span',{staticClass:"VueTables__heading"},[_vm._v(_vm._s(_vm.headings[column]))])])}),0)]),_c('tbody',_vm._l((_vm.offers),function(row){return _c('tr',{key:row.offerId,staticClass:"VueTables__row"},[_c('td',[_vm._v(_vm._s(row.companyName))]),_c('td',[(row.offerType === 'C')?_c('input-component-format',{attrs:{"only-numeric":true,"input-param":_vm.inputCoupon(row)}}):_vm._e()],1),_c('td',[_vm._v(" "+_vm._s(row.instrumentLabel)+" ")]),_c('td',[_c('input-component-format',{attrs:{"only-numeric":true,"input-param":_vm.inputMinimumSize(row)}})],1),_c('td',[_c('input-component-format',{attrs:{"only-numeric":true,"input-param":_vm.inputMaximumSize(row)}})],1),_c('td',[_c('div',{staticClass:"actions"},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    placement: 'bottom-center',
                    content: ("" + (_vm.$t(
                      'components.IOIOffer.OfferGrid.approveOfferTooltip'
                    ))),
                  }),expression:"{\n                    placement: 'bottom-center',\n                    content: `${$t(\n                      'components.IOIOffer.OfferGrid.approveOfferTooltip',\n                    )}`,\n                  }"}]},[(
                      _vm.permAvailable(_vm.Permission.can_withdraw_offer) &&
                        row.approvalData &&
                        row.approvalData.action === 'Withdraw'
                    )?_c('svg',{class:'actions__approve actions__icon',staticStyle:{"width":"24px","height":"24px"},attrs:{"viewBox":"0 0 24 24","svg-inline":'',"role":'presentation',"focusable":'false',"tabindex":'-1'},on:{"click":function($event){return _vm.confirmApproveOffer(row)}}},[_c('path',{attrs:{"fill":"currentColor","d":"M12.3 8.93L9.88 6.5h5.62V10H17V5H9.88l2.42-2.43-1.06-1.07L7 5.75 11.24 10l1.06-1.07M12 14a3 3 0 103 3 3 3 0 00-3-3m-9-3v12h18V11m-2 8a2 2 0 00-2 2H7a2 2 0 00-2-2v-4a2 2 0 002-2h10a2 2 0 002 2z"}})]):_vm._e()]),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    placement: 'bottom-center',
                    content: ("" + (_vm.$t(
                      'components.IOIOffer.OfferGrid.approveOfferTooltip'
                    ))),
                  }),expression:"{\n                    placement: 'bottom-center',\n                    content: `${$t(\n                      'components.IOIOffer.OfferGrid.approveOfferTooltip',\n                    )}`,\n                  }"}]},[(
                      _vm.permAvailable(_vm.Permission.can_approve_multieye) &&
                        row.approvalData &&
                        row.approvalData.action === 'Create' &&
                        row.state !== 'outstanding'
                    )?_c('svg',{class:'actions__approve actions__icon',staticStyle:{"width":"24px","height":"24px"},attrs:{"viewBox":"0 0 24 24","svg-inline":'',"role":'presentation',"focusable":'false',"tabindex":'-1'},on:{"click":function($event){return _vm.confirmApproveOffer(row)}}},[_c('path',{attrs:{"fill":"currentColor","d":"M21 7L9 19l-5.5-5.5 1.41-1.41L9 16.17 19.59 5.59 21 7z"}})]):_vm._e()]),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    placement: 'bottom-center',
                    content: ("" + (_vm.$t(
                      'components.IOIOffer.OfferGrid.allApprovedOfferTooltip'
                    ))),
                  }),expression:"{\n                    placement: 'bottom-center',\n                    content: `${$t(\n                      'components.IOIOffer.OfferGrid.allApprovedOfferTooltip',\n                    )}`,\n                  }"}]},[(
                      _vm.permAvailable(_vm.Permission.can_approve_multieye) &&
                        row.approvalData &&
                        row.approvalData.action === 'Create' &&
                        row.state === 'outstanding'
                    )?_c('svg',{class:'actions__approved actions__icon',staticStyle:{"width":"24px","height":"24px"},attrs:{"viewBox":"0 0 24 24","svg-inline":'',"role":'presentation',"focusable":'false',"tabindex":'-1'},on:{"click":function($event){return _vm.confirmApproveOffer(row)}}},[_c('path',{attrs:{"fill":"currentColor","d":"M.41 13.41L6 19l1.41-1.42L1.83 12m20.41-6.42L11.66 16.17 7.5 12l-1.43 1.41L11.66 19l12-12M18 7l-1.41-1.42-6.35 6.35 1.42 1.41L18 7z"}})]):_vm._e()])])]),_c('td',[(row.madeForCompany === _vm.selectedCompanyIdGetter)?_c('div',{staticClass:"actions"},[(
                    !_vm.multiEyeSettings &&
                      _vm.permAvailable(_vm.Permission.can_edit_offer)
                  )?_c('img',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    placement: 'bottom-center',
                    content: ("" + (_vm.$t(
                      'components.IOIOffer.OfferGrid.updateOfferTooltip'
                    ))),
                  }),expression:"{\n                    placement: 'bottom-center',\n                    content: `${$t(\n                      'components.IOIOffer.OfferGrid.updateOfferTooltip',\n                    )}`,\n                  }"}],staticClass:"actions__icon",attrs:{"src":require("@/assets/images/edit-grid.png"),"alt":"Edit offer"},on:{"click":function($event){return _vm.selectOffer(row)}}}):_vm._e(),(
                    _vm.permAvailable(_vm.Permission.can_withdraw_offer) &&
                      row.approvalData &&
                      row.approvalData.action !== 'Withdraw'
                  )?_c('img',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    placement: 'bottom-center',
                    content: ("" + (_vm.$t(
                      'components.IOIOffer.OfferGrid.removeOfferTooltip'
                    ))),
                  }),expression:"{\n                    placement: 'bottom-center',\n                    content: `${$t(\n                      'components.IOIOffer.OfferGrid.removeOfferTooltip',\n                    )}`,\n                  }"}],staticClass:"actions__icon",attrs:{"src":require("@/assets/images/clear.png"),"alt":"Remove offer"},on:{"click":function($event){return _vm.confirmRemoveOffer(row)}}}):_vm._e()]):_vm._e()])])}),0)])])]),_c('confirm',{attrs:{"show-modal-param":_vm.showConfirmModal,"content-param":_vm.confirmData},on:{"onCancel":_vm.withdrawIOICancellation,"onConfirm":_vm.submitWithdrawOffer}}),_c('modal-window',{attrs:{"modal-key":"approveWindow","modal-style":"small medium-v","icon-size":"large","init-open":false,"no-close":false,"modal-title":_vm.$t('components.OfferApprove.title')}},[_c('offer-approve',{attrs:{"offer":_vm.offerToBeApproved}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }