'use strict';

import Vue from 'vue';
import Router from 'vue-router';
import SignUpNew from './views/authentication/SignUpNew.vue';
import Login from './views/authentication/Login.vue';
import Logout from './views/authentication/Logout.vue';
import PasswordChangeSubmit from './views/authentication/PasswordChangeSubmit.vue';
import Password from './views/authentication/Password.vue';
import IOIListInvestor from './views/ioi/IOIListInvestor.vue';
import IOIListMarketViewer from './views/ioi/IOIListMarketViewer.vue';
import MyAccountIssuer from './views/myAccounts/MyAccountIssuer.vue';
import MyAccountInvestor from './views/myAccounts/MyAccountInvestor.vue';
import MyAccountLeadManager from './views/myAccounts/MyAccountLeadManager.vue';
import PreferencesMail from './components/user/PreferencesMail.vue';
import TermsOfUse from './views/TermsOfUse.vue';
import PrivacyStatement from './views/PrivacyStatement.vue';
import UnderConstruction from './views/UnderConstruction.vue';
import HomeSmallDevices from './views/HomeSmallDevices.vue';
import IOI from './views/ioi/IOI.vue';
import ConfirmationPage from '@/views/confirmation/ConfirmationPage.vue';
import BackofficeConfirmation from '@/views/confirmation/BackofficeConfirmation.vue';
import AccountDashboard from '@/views/backoffice/AccountDashboard.vue';
import TcDashboard from '@/views/backoffice/TcDashboard.vue';
import EventList from '@/views/events/EventList.vue';
import { requireAuth } from '@/util/router';
import Error404 from '@/views/errors/Error404.vue';
import IRPForm from '@/views/irp/IRPForm.vue';
import IRPChart from '@/views/irp/IRPChart.vue';
import ESTRChart from '@/views/irp/ESTRChart.vue';
import desktopSizes from '@/assets/screen-sizes';
import LandingPage from '@/views/LandingPage.vue';
import MyAccountMarketViewer from '@/views/myAccounts/MyAccountMarketViewer.vue';
import ContactPage from '@/views/platform/Contact.vue';
import Settings from '@/views/platform/Settings.vue';
import IOIDetail from '@/views/ioi/IOIDetail.vue';
import FaqPage from '@/views/platform/FaqPage.vue';
import ActionCenter from '@/views/actionCenter/ActionCenter.vue';
import BlogOverview from '@/views/blog/BlogOverview.vue';
import BlogDetail from '@/views/blog/BlogDetail.vue';
import { checkIfPageResponsive } from '@/util/generalUtils';

declare let gtag: Function;
Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,

  routes: [
    { path: '/', name: 'landing-page', component: LandingPage },
    { path: '/contact', name: 'contact-page', component: ContactPage },
    { path: '/faq', name: 'faq-page', component: FaqPage },
    {
      path: '/news-and-research',
      name: 'blog-overview',
      component: BlogOverview,
    },
    {
      path: '/news/:documentId',
      name: 'blog-detail',
      component: BlogDetail,
      props: true,
    },
    {
      path: '/settings',
      name: 'settings',
      component: Settings,
      beforeEnter: requireAuth,
      meta: {
        permission: 'marketViewer;issuer;investor;leadManager;',
      },
    },
    // authentication
    {
      path: '/account/:flow/:accountType/:step/:uid?',
      name: 'signupnew',
      component: SignUpNew,
      props: true,
      meta: {
        scrollToTop: true,
      },
    },
    { path: '/login', name: 'login', component: Login },
    { path: '/home', name: 'home', component: HomeSmallDevices },
    {
      path: '/reset-password/:userAccountId/:token',
      name: 'reset-password',
      component: Password,
      props: true,
    },
    {
      path: '/password-change-confirmation/:userAccountId/:token',
      name: 'password-confirmation',
      component: PasswordChangeSubmit,
      props: true,
    },
    { path: '/change-password', name: 'change-password', component: Password },
    { path: '/logout', name: 'logout', component: Logout },
    {
      path: '/preference-investor',
      name: 'preference-investor',
      component: PreferencesMail,
      beforeEnter: requireAuth,
      meta: {
        permission: 'investor;marketViewer',
      },
    },
    // instruments
    {
      path: '/ioi-list-investor',
      name: 'ioi-list-investor',
      component: IOIListInvestor,
      beforeEnter: requireAuth,
      meta: {
        permission: 'investor',
      },
      props: true,
    },
    {
      path: '/ioi-list-viewer',
      name: 'ioi-list-viewer',
      component: IOIListMarketViewer,
      beforeEnter: requireAuth,
      meta: {
        permission: 'marketViewer',
      },
    },
    {
      path: '/myaccount-issuer',
      name: 'myaccount-issuer',
      component: MyAccountIssuer,
      beforeEnter: requireAuth,
      meta: {
        permission: 'issuer',
      },
      props: true,
    },
    {
      path: '/myaccount-investor',
      name: 'myaccount-investor',
      component: MyAccountInvestor,
      beforeEnter: requireAuth,
      meta: {
        permission: 'investor',
      },
      props: true,
    },
    {
      path: '/myaccount-lm',
      name: 'myaccount-lm',
      component: MyAccountLeadManager,
      beforeEnter: requireAuth,
      meta: {
        permission: 'leadManager',
      },
      props: true,
    },
    {
      path: '/ioi/:ioiId?',
      name: 'ioi',
      component: IOI,
      beforeEnter: requireAuth,
      meta: {
        permission: 'issuer;leadManager',
      },
      props: true,
    },
    {
      path: '/ioi-detail/:IOIReference?',
      name: 'ioi-detail',
      component: IOIDetail,
      beforeEnter: requireAuth,
      meta: {
        permission: 'issuer;investor;marketViewer',
      },
      props: true,
    },
    {
      path: '/irpform',
      name: 'irp-form',
      meta: {
        permission: 'leadManager;',
      },
      beforeEnter: requireAuth,
      component: IRPForm,
    },
    {
      path: '/irpchart',
      name: 'irp-chart',
      beforeEnter: requireAuth,
      meta: {
        permission: 'marketViewer;issuer;investor;leadManager;',
      },
      component: IRPChart,
    },
    {
      path: '/estrchart',
      name: 'estr-chart',
      beforeEnter: requireAuth,
      meta: {
        permission: 'marketViewer;issuer;investor;leadManager;',
      },
      component: ESTRChart,
    },
    // event pages
    {
      path: '/events',
      name: 'events',
      beforeEnter: requireAuth,
      meta: {
        permission: 'marketViewer;issuer;investor;leadManager;backOffice',
      },
      component: EventList,
    },
    // Action Center
    {
      path: '/action-center',
      name: 'actionCenter',
      beforeEnter: requireAuth,
      meta: {
        permission: 'marketViewer;issuer;investor;leadManager;backOffice',
      },
      component: ActionCenter,
    },
    // general purpose pages
    {
      path: '/construction',
      name: 'construction',
      component: UnderConstruction,
    },
    {
      path: '/terms-of-use',
      name: 'terms-of-use',
      component: TermsOfUse,
    },
    {
      path: '/privacy',
      name: 'privacy-statement',
      component: PrivacyStatement,
    },
    {
      path: '/confirmation/signup/:userAccountId/:accountConfirmation',
      name: 'signup-confirmation-page',
      component: ConfirmationPage,
      props: true,
    },
    {
      path: '/404',
      component: Error404,
    },
    {
      path: '*',
      redirect: '/404',
    },
    // backoffice pages
    {
      path: '/confirmation/:action/:email',
      name: 'confirmation-page',
      component: BackofficeConfirmation,
      props: true,
    },
    {
      path: '/backoffice',
      name: 'backoffice-page',
      beforeEnter: requireAuth,
      meta: {
        permission: 'backOffice',
      },
      component: AccountDashboard,
    },
    {
      path: '/tc-dashboard',
      name: 'tc-dashboard-page',
      beforeEnter: requireAuth,
      meta: {
        permission: 'backOffice',
      },
      component: TcDashboard,
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    // If user navigates from a diferent page it scrolls to top
    // If navigating within the same page (props etc) scroll position remains
    if (!to || !to.meta) {
      return savedPosition;
    }
    if (to.meta.scrollToTop && to.name !== from.name) {
      return { x: 0, y: 0 };
    }
    // if saved position is false no scrolling happens
    return savedPosition;
  },
});

router.beforeEach((to, from, next) => {
  const lastPage: string =
    from.name === undefined || from.name === null ? '' : from.name;
  localStorage.setItem('lastPage', lastPage);
  // also edit navigation component with similar code
  if (
    checkIfPageResponsive(to.name) &&
    window.innerWidth <= desktopSizes.medium
  ) {
    router.push({ name: 'home' }).catch(err => {});
  } else {
    next();
  }
});

router.afterEach((to, from) => {
  // dont do first pageview
  if (from.name !== null) {
    // log pageview to analytics
    if (gtag) {
      gtag('event', 'page_view', {
        page_path: to.path,
      });
    }
  }
});

export default router;
