'use strict';

import { forEach, isEmpty } from 'lodash';
import router from '@/router';
import { IOI } from '@/types/instrumentsTypes';
import { RadioButtonOption } from '@/components/inputs/inputTypes';
import { formatNumberLocaleMinMax } from '@/util/digits';
import moment from 'moment';
import { stringLiteral } from '@babel/types';

export function isDutchStateIndustry(type: string): boolean {
  return type === 'DS';
}

export function getIssueType(issueType: string): string {
  if (issueType === '1') {
    return `${router.app.$t('components.IOIForm.issueType1')}`;
  }
  if (issueType === '2') {
    return `${router.app.$t('components.IOIForm.issueType2')}`;
  }
  if (issueType === '3') {
    return `${router.app.$t('components.IOIForm.issueType3')}`;
  }
  return '';
}

export function getIndustry(industry: string): string {
  if (industry === 'GL') {
    return `${router.app.$t('components.IOIForm.industryGL')}`;
  }
  if (industry === 'HA') {
    return `${router.app.$t('components.IOIForm.industryHA')}`;
  }
  if (industry === 'DS') {
    return `${router.app.$t('components.IOIForm.industryDS')}`;
  }
  if (industry === 'GO') {
    return `${router.app.$t('components.IOIForm.industryGO')}`;
  }
  if (industry === 'FI') {
    return `${router.app.$t('components.IOIForm.industryFI')}`;
  }
  if (industry === 'HC') {
    return `${router.app.$t('components.IOIForm.industryHC')}`;
  }
  if (industry === 'DP') {
    return `${router.app.$t('components.IOIForm.industryDPS')}`;
  }
  if (industry === 'RW') {
    return `${router.app.$t('components.IOIForm.industryRW')}`;
  }
  return ''; // don't change
}

export function getRankingType(rankingType: string): string {
  if (rankingType === '1') {
    return `${router.app.$t('components.IOIForm.rankingTypeUnsecured')}`;
  } // Senior Unsecured
  if (rankingType === '2') {
    return `${router.app.$t('components.IOIForm.rankingTypeGuaranteed')}`;
  }
  return '';
}

export function getIndicativeType(type: string): string {
  if (type === 'Fixed') {
    return `${router.app.$t('components.IOIForm.indicativeTypeFixed')}`;
  } // Fixed Rate
  if (type === 'Floating') {
    return `${router.app.$t('components.IOIForm.indicativeTypeFloating')}`;
  }
  return '';
}

export function getStructure(structure: string): string {
  if (structure === 'SC') {
    return `${router.app.$t('components.IOIForm.structureSuppliers')}`;
  }
  if (structure === 'BC') {
    return `${router.app.$t('components.IOIForm.structureBuyers')}`;
  }
  if (structure === 'PF') {
    return `${router.app.$t('components.IOIForm.structureProject')}`;
  }
  return '';
}

export function getRankingGuarantee(guarantee: string): string {
  if (guarantee === '1') {
    return `${router.app.$t('components.IOIForm.guarantee1')}`;
  } // Implicit
  if (guarantee === '2') {
    return `${router.app.$t('components.IOIForm.guarantee2')}`;
  } // Explicit
  if (guarantee === '3') {
    return `${router.app.$t('components.IOIForm.guarantee3')}`;
  } // Explicit
  return '';
}

export function getInstrumentType(instrumentType: number | string): string {
  if (instrumentType === 1 || instrumentType === '1') {
    return `${router.app.$t('components.IOIForm.instrument1')}`;
  } // Loan
  if (instrumentType === 2 || instrumentType === '2') {
    return `${router.app.$t('components.IOIForm.instrument2')}`;
  }
  if (instrumentType === 3 || instrumentType === '3') {
    return `${router.app.$t('components.IOIForm.instrument3')}`;
  }
  if (instrumentType === 4 || instrumentType === '4') {
    return `${router.app.$t('components.IOIForm.instrument4')}`;
  }
  if (instrumentType === 5 || instrumentType === '5') {
    return `${router.app.$t('components.IOIForm.instrument5')}`;
  }
  if (instrumentType === 6 || instrumentType === '6') {
    return `${router.app.$t('components.IOIForm.instrument6')}`;
  }
  if (instrumentType === 7 || instrumentType === '7') {
    return `${router.app.$t('components.IOIForm.instrument7')}`;
  }
  if (instrumentType === 9 || instrumentType === '9') {
    return `${router.app.$t('components.IOIForm.instrument9')}`;
  }
  return '';
}

export function getInstrumentTypeLabel(
  instrumentType: number | string,
): string {
  if (instrumentType === 1 || instrumentType === '1') {
    return 'components.IOIForm.instrument1';
  } // Loan
  if (instrumentType === 2 || instrumentType === '2') {
    return 'components.IOIForm.instrument2';
  }
  if (instrumentType === 3 || instrumentType === '3') {
    return 'components.IOIForm.instrument3';
  }
  if (instrumentType === 4 || instrumentType === '4') {
    return 'components.IOIForm.instrument4';
  }
  return '';
}

export function multipleInstruments(instruments: number[]): any[] {
  const formattedInstruments: any[] = [];
  forEach(instruments, (iter: number) => {
    formattedInstruments.push({ value: iter, label: getInstrumentType(iter) });
  });
  return formattedInstruments;
}

export function getFrequency(frequencyCode: string): string {
  if (frequencyCode === '1') {
    return `${router.app.$t('components.IOIForm.frequency1')}`;
  } // Annual
  if (frequencyCode === '2') {
    return `${router.app.$t('components.IOIForm.frequency2')}`;
  } // Semi Annual
  if (frequencyCode === '3') {
    return `${router.app.$t('components.IOIForm.frequency3')}`;
  }
  if (frequencyCode === '4') {
    return `${router.app.$t('components.IOIForm.frequency4')}`;
  }
  return '';
}

export function getDayCount(dayCode: string): string {
  if (dayCode === '1') {
    return 'act/act following (ICMA)';
  }
  if (dayCode === '2') {
    return '30/360 following (ICMA)';
  }
  if (dayCode === '3') {
    return 'act/360 following (ICMA)';
  }
  if (dayCode === '4') {
    return 'act/365 following (ICMA)';
  }
  return '';
}

export function getRedemptionForm(redemptionCode: string): string {
  if (redemptionCode === 'BU') {
    return `${router.app.$t('components.IOIForm.redemptionForm1')}`;
  } // Bullet
  if (redemptionCode === 'LA') {
    return `${router.app.$t('components.IOIForm.redemptionForm2')}`;
  } // Linear Amortization
  if (redemptionCode === 'BU25') {
    return `${router.app.$t('components.IOIForm.redemptionForm3')}`;
  }
  if (redemptionCode === 'AN') {
    return `${router.app.$t('components.IOIForm.redemptionFormAnnuity')}`;
  }
  return '';
}

export function getRanking(rankingCode: string): string {
  if (isEmpty(rankingCode)) {
    return 'components.IOIForm.ratings.noRating';
  }
  return `components.IOIForm.ratings.${rankingCode.trim()}`;
}

export function getMoodysRanking(rankingCode: string): string {
  if (isEmpty(rankingCode)) {
    return 'components.IOIForm.moodysRatings.noRating';
  }
  return `components.IOIForm.moodysRatings.${rankingCode.trim()}`;
}

export function redemptionFormList(
  industryCodeOrInstrumentCode: string,
): { value: string; label: string }[] {
  if (industryCodeOrInstrumentCode === 'DS') {
    return [
      { value: 'BU', label: 'components.IOIForm.redemptionForm1' },
      { value: 'LA', label: 'components.IOIForm.redemptionForm2' },
      { value: 'BU25', label: 'components.IOIForm.redemptionForm3' },
      { value: 'AN', label: 'components.IOIForm.redemptionFormAnnuity' },
    ];
  }
  if (['4', '5', '6', '7'].includes(industryCodeOrInstrumentCode)) {
    return [{ value: 'BU', label: 'components.IOIForm.redemptionForm1' }];
  }
  return [
    { value: 'BU', label: 'components.IOIForm.redemptionForm1' },
    { value: 'LA', label: 'components.IOIForm.redemptionForm2' },
    { value: 'AN', label: 'components.IOIForm.redemptionFormAnnuity' },
  ];
}

export function rankingTypeOptions(instrumentCode: string) {
  if (['4', '5', '6', '7'].includes(instrumentCode)) {
    return [
      { value: '3', label: 'components.IOIForm.rankingTypeMoreUnsecured' },
    ];
  } else {
    return [
      { value: '1', label: 'components.IOIForm.rankingTypeUnsecured' },
      { value: '2', label: 'components.IOIForm.rankingTypeGuaranteed' },
    ];
  }
}

export function getBucketSize(bucketCode: number): string {
  if (bucketCode === 0) {
    return `${router.app.$t('components.IOIForm.bucketSize0')}`;
  }
  if (bucketCode === 1) {
    return `${router.app.$t('components.IOIForm.bucketSize1')}`;
  }
  if (bucketCode === 2) {
    return `${router.app.$t('components.IOIForm.bucketSize2')}`;
  }
  if (bucketCode === 3) {
    return `${router.app.$t('components.IOIForm.bucketSize3')}`;
  }
  if (bucketCode === 4) {
    return `${router.app.$t('components.IOIForm.bucketSize4')}`;
  }
  if (bucketCode === 5) {
    return `${router.app.$t('components.IOIForm.bucketSize5')}`;
  }
  return '';
}

export function getMaturityBucket(maturityBucket: string): string {
  if (maturityBucket) {
    return `${router.app.$t(`components.IOIForm.${maturityBucket}`)}`;
  }
  return '';
}

export function getSpread(spreadCode: string): string {
  if (spreadCode === 'IRS') {
    return 'IRS (VS 6M)';
  }
  if (spreadCode === 'NETHER') {
    return 'NETHER';
  }
  if (spreadCode === 'DBR') {
    return 'DBR';
  }
  if (spreadCode === 'OLO') {
    return 'OLO / BGB';
  }
  if (spreadCode === 'OAT') {
    return 'OAT';
  }
  return '';
}

export function offersOptions(ioi: IOI): RadioButtonOption[] {
  const options: RadioButtonOption[] = [];
  forEach(ioi.ioiOffers, offer => {
    options.push({
      id: offer.offerId,
      value: offer.offerId,
      labelCode: `${offer.couponPercentage}% ${offer.minSize}mio ${offer.maxSize}mio`,
      static: true,
    });
  });
  return options;
}

export function indicativeTypeOptionList(
  instrumentType: string,
): { value: string; label: string }[] {
  if (['4', '5', '6', '7'].includes(instrumentType)) {
    return [
      { value: 'Fixed', label: 'components.IOIForm.indicativeTypeFixed' },
    ];
  }
  return [
    { value: 'Fixed', label: 'components.IOIForm.indicativeTypeFixed' },
    { value: 'Floating', label: 'components.IOIForm.indicativeTypeFloating' },
  ];
}

export function dayCountOptionList(instrumentType: string) {
  if (['4', '5', '6', '7'].includes(instrumentType)) {
    return [{ value: '3', label: 'act/360 following (ICMA)' }];
  }
  return [
    { value: '1', label: 'act/act following (ICMA)' },
    { value: '2', label: '30/360 following (ICMA)' },
    { value: '3', label: 'act/360 following (ICMA)' },
    { value: '4', label: 'act/365 following (ICMA)' },
  ];
}

export function buildGridFullFirstLine(ioi: IOI): string[] {
  let firstLine: string | null = '';
  if (!isEmpty(ioi.structure)) {
    firstLine = `${getStructure(ioi.structure)}`;
  }
  if (isEmpty(ioi.creditRatingF)) {
    return [firstLine];
  }
  return [`${ioi.creditRatingF}`, `${firstLine}`];
}

export function buildGridFullSecondLine(ioi: IOI): string[] {
  let repaymentFrequency: string = '';
  if (ioi.repaymentFrequency !== undefined && ioi.repaymentFrequency !== '') {
    const repaymentFrequencyText = router.app.$t(
      'components.IOIRow.repaymentFrequency',
    );
    repaymentFrequency = `${repaymentFrequencyText} ${getFrequency(
      ioi.repaymentFrequency,
    )}`;
  }
  const couponString: string | null =
    ioi.couponFrequency === undefined
      ? ''
      : `${router.app.$t('components.IOIRow.couponFrequency')} ${getFrequency(
          ioi.couponFrequency,
        )}`;
  const redemptionString: string | null =
    ioi.redemptionForm === undefined
      ? ''
      : `${getRedemptionForm(ioi.redemptionForm)}`;
  const typeString: string | null =
    ioi.indicativeType === undefined
      ? ''
      : `${getIndicativeType(ioi.indicativeType)}`;
  const minimumPiece: string | null =
    ioi.minimumPieceWithFormat === ''
      ? ''
      : `${router.app.$t('components.IOIRow.minimumPiece')} ${
          ioi.minimumPieceWithFormat
        }`;
  let secondLine: string[] = [
    `${redemptionString}`,
    `${typeString}`,
    `${minimumPiece}`,
    `${couponString}`,
    `${repaymentFrequency}`,
  ];
  // check if a loan => do not show minimum piece
  if (ioi.instrumentTypes && ioi.instrumentTypes.indexOf(1) > -1) {
    secondLine = [
      `${redemptionString}`,
      `${typeString}`,
      `${couponString}`,
      `${repaymentFrequency}`,
    ];
  }
  if (ioi.ecaInsuredPercentage === 0) {
    return secondLine;
  }
  return [...secondLine, `ECA ${ioi.ecaInsuredPercentage}%`];
}

export function getIOIState(stateCode: string): string {
  if (stateCode === 'open') {
    return `${router.app.$t('components.IOIStates.open')}`;
  }
  if (stateCode === 'closed') {
    return `${router.app.$t('components.IOIStates.closed')}`;
  }
  if (stateCode === 'closing') {
    return `${router.app.$t('components.IOIStates.closing')}`;
  }
  if (stateCode === 'matched') {
    return `${router.app.$t('components.IOIStates.matched')}`;
  }
  if (stateCode === 'expired') {
    return `${router.app.$t('components.IOIStates.expired')}`;
  }
  if (stateCode === 'draft') {
    return `${router.app.$t('components.IOIStates.draft')}`;
  }
  if (stateCode === 'withdrawn') {
    return `${router.app.$t('components.IOIStates.withdrawn')}`;
  }
  return '';
}

export function hasMTN(instruments: any[]): boolean {
  let includesMTN: boolean = false;
  forEach(instruments, (item: any) => {
    includesMTN = includesMTN || item.value === '2' || item.value === '3';
  });
  return includesMTN;
}

export function getIOIDefaultValues(industry: string, instruments: any[]): any {
  const ioiDefaults: any = {};
  ioiDefaults.instruments =
    instruments === undefined || instruments === null ? [] : instruments;
  ioiDefaults.ecaInsured = undefined;
  ioiDefaults.ecaVRuled = '';
  ioiDefaults.maturityVRule = 'required|max_value:100';
  ioiDefaults.couponFrequency = {
    value: '1',
    label: 'components.IOIForm.frequency1',
  }; // Annual
  ioiDefaults.redemptionForm = {
    value: 'BU',
    label: 'components.IOIForm.redemptionForm1',
  }; // Bullet
  ioiDefaults.rankingType = {
    value: '1',
    label: 'components.IOIForm.rankingTypeUnsecured',
  }; // Senior Unsecured
  ioiDefaults.rankingGuarantee = {
    value: '1',
    label: 'components.IOIForm.guarantee1',
  };
  ioiDefaults.rankingGuaranteeDisabled = false;
  ioiDefaults.indicativeType = {
    value: 'Fixed',
    label: 'components.IOIForm.indicativeTypeFixed',
  }; // Fixed rate
  ioiDefaults.dayCount = {
    value: '1',
    label: getDayCount('1'),
  }; // 'act/act following (ICMA)'
  if (
    industry === 'GL' &&
    instruments.length === 0 &&
    instruments[0].value === '1'
  ) {
    // Government Local and Loan
    ioiDefaults.instruments = [
      { value: '1', label: getInstrumentTypeLabel(1) },
    ];
    ioiDefaults.rankingGuaranteeDisabled = false;
  } else if (industry === 'GL' && hasMTN(instruments)) {
    ioiDefaults.rankingGuaranteeDisabled = false;
  } else if (
    industry === 'HA' &&
    instruments.length === 0 &&
    instruments[0].value === '1'
  ) {
    // Housing Association and Loan
    ioiDefaults.instruments = [
      { value: '1', label: getInstrumentTypeLabel(1) },
    ];
    ioiDefaults.rankingGuarantee = {
      value: '2',
      label: 'components.IOIForm.guarantee2',
    }; // Explicit
    ioiDefaults.rankingGuaranteeDisabled = true;
  } else if (industry === 'HA') {
    ioiDefaults.rankingGuarantee = {
      value: '2',
      label: 'components.IOIForm.guarantee2',
    }; // Explicit
  } else if (industry === 'DS') {
    // Dutch State Guaranteed Export and Loan
    ioiDefaults.instruments = [
      { value: '1', label: getInstrumentTypeLabel(1) },
    ];
    ioiDefaults.instrumentsDisabled = true;
    ioiDefaults.ecaInsured = 100;
    ioiDefaults.ecaVRuled = 'required|integer|max_value:100';
    ioiDefaults.maturityVRule = 'required|max_value:12';
    ioiDefaults.structure = 'SC';
    ioiDefaults.couponFrequency = {
      value: '2',
      label: 'components.IOIForm.frequency2',
    }; // Semi Annual
    ioiDefaults.redemptionForm = {
      value: 'LA',
      label: 'components.IOIForm.redemptionForm2',
    }; // Linear Amortization
    ioiDefaults.rankingGuarantee = {
      value: '2',
      label: 'components.IOIForm.guarantee2',
    }; // Explicit
    ioiDefaults.rankingGuaranteeDisabled = true;
    ioiDefaults.ratingSP = 'AAA';
    ioiDefaults.ratingSPLabel = 'AAA';
    ioiDefaults.ratingF = 'AAA';
    ioiDefaults.ratingFLabel = 'AAA';
    ioiDefaults.ratingM = 'Aaa';
    ioiDefaults.ratingMLabel = 'Aaa';
  }
  if (hasMTN(instruments)) {
    ioiDefaults.minimumPiece = 100000;
  } else {
    ioiDefaults.minimumPiece = 100000;
  }
  ioiDefaults.documentationFormOptions = getDocumentationFormOptions(
    industry,
    instruments[0].value,
  );

  return ioiDefaults;
}

export function getDocumentationFormOptions(
  industry: string,
  instruments: string,
): any {
  if (industry === 'HA') {
    return [
      {
        value: 'A3',
        label: 'components.IOIForm.docOptions.a3',
      },
      {
        value: 'A4',
        label: 'components.IOIForm.docOptions.a4',
      },
      {
        value: 'other',
        label: 'components.IOIForm.docOptions.other',
      },
    ];
  }
  return [
    {
      value: 'other',
      label: 'components.IOIForm.docOptions.other',
    },
  ];
}

export function instrumentsFullLabel(instruments: any[]): string {
  let fullLabel: string = '';
  forEach(instruments, (item: any) => {
    fullLabel =
      fullLabel === '' ? `${item.label}` : `${item.label} or ${fullLabel}`;
  });
  return fullLabel;
}

export function formatCoupon(coupon: string): string {
  const couponDecimals = parseFloat(coupon) / 100;

  return formatNumberLocaleMinMax(couponDecimals, 3, 3);
}

export function instrumentsLabel(instruments: string[]): string {
  let fullLabel: string = '';
  forEach(instruments, (item: string) => {
    fullLabel =
      fullLabel === ''
        ? `${getInstrumentType(item)}`
        : `${getInstrumentType(item)} ${router.app.$t(
            'components.IOIRow.instrumentSeparation',
          )} ${fullLabel}`;
  });
  return fullLabel;
}

export function createMaturityString(days: number) {
  if (days === 0) {
    return router.app.$t('No valid maturity date selected');
  } else if (days < 91) {
    return `${Math.round(days)} days`;
  } else if (days < 366) {
    return `${Math.round(days / 30)} months`;
  } else {
    return `${Math.round(days / 365)} years`;
  }
}

export function calculateDays(maturityDate: string, settlementDate: string) {
  if (maturityDate && settlementDate) {
    return moment(maturityDate).diff(moment(settlementDate), 'days');
  } else {
    return 0;
  }
}
