





















































import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import InputComponent from '@/components/inputs/InputComponent.vue';
import ButtonComponent from '@/components/utils/ButtonComponent.vue';
import InputDateComponent from '@/components/inputs/InputDateComponent.vue';
import InputFileComponent from '@/components/inputs/InputFileComponent.vue';
import InputTimePickerComponent from '@/components/inputs/InputTimePickerComponent.vue';
import CollapsibleComponent from '@/components/utils/CollapsibleComponent.vue';
import IRPForm from '@/views/irp/IRPForm.vue';
import irpApi from '@/api/irpApi';
const AuthenticationNameSpace = namespace('authentication/');
const NotificationsNameSpace = namespace('notifications/');

@Component({
  name: 'ESTRForm',
  components: {
    ButtonComponent,
    InputComponent,
    InputDateComponent,
    InputFileComponent,
    InputTimePickerComponent,
    CollapsibleComponent,
  },
})
export default class ESTRForm extends IRPForm {
  uploadIRPSubmit() {
    irpApi.submitESTRInterestRatePage({
      userAccountId: this.myAccount,
      snapshotDate: this.inputIRPDate.value,
      snapshotTime: this.inputIRPTime.value,
      estrDataFile: this.csvIRPFile,
    });
  }
}
