'use strict';

import api from './index';
import {
  DtoIOI,
  Offer,
  PayloadIOI,
  PayloadInterested,
  PayloadIOIId,
  PayloadAcceptOffers,
} from '@/types/instrumentsTypes';
import { AccountPayload } from '@/types/accountTypes';
import { RadioButtonOption } from '@/components/inputs/inputTypes';
import router from '@/router';

export function getIOIList(payload: PayloadIOI) {
  return api.get(`/instrument/ioi/${payload.state}/${payload.withOffers}`);
}

export function getIOIByReference(referenceNumber: string) {
  return api.get(`/instrument/ioi/${referenceNumber}`);
}

export default {
  createIOI(payloadIOI: DtoIOI) {
    return api.post('/instrument/ioi', payloadIOI);
  },

  editIOI(payloadIOI: DtoIOI) {
    return api.put('/instrument/ioi', payloadIOI);
  },

  prepopulateIOI(payload: AccountPayload) {
    return api.post('/instrument/ioi/prepopulate', payload);
  },

  prepopulateEditIOI(payload: PayloadInterested) {
    return api.post('/instrument/ioi/prepopulate-edit', payload);
  },

  withdrawIOI(payload: PayloadIOIId) {
    return api.post('/instrument/ioi/withdraw', payload);
  },

  deleteIOI(ioiId: string) {
    return api.delete(`/instrument/ioi/remove/${ioiId}`);
  },

  sendToMarketIOI(payload: PayloadIOIId) {
    return api.post('/instrument/ioi/send-to-market', payload);
  },

  copyIOI(payload: {
    indicationOfInterestId: string;
    sendToMarket: boolean;
    userAccountId: string;
  }) {
    return api.post('/instrument/ioi/copy', payload);
  },

  sendIOIEmails(payload: object) {
    return api.post('/instrument/notification/send-to-external', payload);
  },

  // Offers
  createOffer(newOffer: Offer) {
    return api.post('/instrument/offer/make', newOffer);
  },

  editOffer(newOffer: Offer) {
    return api.post('/instrument/offer/edit', newOffer);
  },

  withdrawOffer(offerId: string) {
    return api.delete(`/instrument/offer/withdraw/${offerId}`);
  },

  acceptOffer(offerAccepted: PayloadAcceptOffers) {
    return api.post('/instrument/offer/accept', offerAccepted);
  },

  requestIndication(payload: object) {
    return api.post('/instrument/ioi/indication', payload);
  },

  // Wishlist
  addTowishList(payload: PayloadInterested) {
    return api.post('/instrument/ioi/interested', payload);
  },

  removeFromWishList(payload: PayloadInterested) {
    const { indicationOfInterestId, userAccountId } = payload;
    return api.delete(
      `/instrument/ioi/interested/${indicationOfInterestId}/${userAccountId}`,
    );
  },

  // Deals
  getIOIDeals(payload: PayloadIOIId) {
    return api.post('/instrument/deal/ioi', payload);
  },

  // Required lists
  industrySectorList(): any {
    return [
      { value: 'GL', label: 'components.IOIForm.industryGL' },
      { value: 'HA', label: 'components.IOIForm.industryHA' },
      { value: 'DS', label: 'components.IOIForm.industryDS' },
      { value: 'GO', label: 'components.IOIForm.industryGO' },
      { value: 'FI', label: 'components.IOIForm.industryFI' },
      { value: 'HC', label: 'components.IOIForm.industryHC' },
      { value: 'DP', label: 'components.IOIForm.industryDPS' },
      { value: 'RW', label: 'components.IOIForm.industryRW' },
    ];
  },

  instrumentTypeList(): any {
    return [
      { value: '1', label: 'components.IOIForm.instrument1' },
      { value: '2', label: 'components.IOIForm.instrument2' },
      { value: '4', label: 'components.IOIForm.instrument4' },
      { value: '5', label: 'components.IOIForm.instrument5' },
      { value: '6', label: 'components.IOIForm.instrument6' },
      { value: '7', label: 'components.IOIForm.instrument7' },
      { value: '9', label: 'components.IOIForm.instrument9' },
    ];
  },

  frequencyList(): any {
    return [
      { value: '1', label: 'components.IOIForm.frequency1' },
      { value: '2', label: 'components.IOIForm.frequency2' },
      { value: '3', label: 'components.IOIForm.frequency3' },
      { value: '4', label: 'components.IOIForm.frequency4' },
    ];
  },

  rankingGuaranteeList(): any {
    return [
      { value: '1', label: 'components.IOIForm.guarantee1' },
      { value: '2', label: 'components.IOIForm.guarantee2' },
      { value: '3', label: 'components.IOIForm.guarantee3' },
    ];
  },

  rankingTypeList(): any {
    return [
      { value: '1', label: 'components.IOIForm.rankingTypeUnsecured' },
      { value: '2', label: 'components.IOIForm.rankingTypeGuaranteed' },
    ];
  },

  indicativeTypeList(): any {
    return [
      { value: 'Fixed', label: 'components.IOIForm.indicativeTypeFixed' },
      { value: 'Floating', label: 'components.IOIForm.indicativeTypeFloating' },
    ];
  },

  bucketSizeList(): any {
    return [
      { value: 1, label: '5-10 mio' },
      { value: 2, label: '10-20 mio' },
      { value: 3, label: '20-30 mio' },
      { value: 4, label: '30-50 mio' },
      { value: 5, label: '> 50 mio' },
    ];
  },

  maturityBucketList(): any {
    const years: string = `${router.app.$t('components.IOIFormConfirm.years')}`;
    return [
      { value: 1, label: `< 5 ${years}` },
      { value: 2, label: `5-10 ${years}` },
      { value: 3, label: `10-20 ${years}` },
      { value: 4, label: `20-30 ${years}` },
      { value: 5, label: `> 30 ${years}` },
    ];
  },

  ratings(): any {
    return [
      { value: '', label: 'components.IOIForm.ratings.noRating' },
      { value: 'AAA', label: 'components.IOIForm.ratings.AAA' },
      { value: 'AA+', label: 'components.IOIForm.ratings.AAPlus' },
      { value: 'AA', label: 'components.IOIForm.ratings.AA' },
      { value: 'AA-', label: 'components.IOIForm.ratings.AAMinus' },
      { value: 'A+', label: 'components.IOIForm.ratings.APlus' },
      { value: 'A', label: 'components.IOIForm.ratings.A' },
      { value: 'A-', label: 'components.IOIForm.ratings.AMinus' },
      { value: 'BBB+', label: 'components.IOIForm.ratings.BBBPlus' },
      { value: 'BBB', label: 'components.IOIForm.ratings.BBB' },
      { value: 'BBB-', label: 'components.IOIForm.ratings.BBBMinus' },
      { value: 'BB+', label: 'components.IOIForm.ratings.BBPlus' },
      { value: 'BB', label: 'components.IOIForm.ratings.BB' },
      { value: 'BB-', label: 'components.IOIForm.ratings.BBMinus' },
      { value: 'B+', label: 'components.IOIForm.ratings.BPlus' },
      { value: 'B', label: 'components.IOIForm.ratings.B' },
      { value: 'B-', label: 'components.IOIForm.ratings.BMinus' },
    ];
  },

  moodysRatings(): any {
    return [
      { value: '', label: 'components.IOIForm.moodysRatings.noRating' },
      { value: 'Aaa', label: 'components.IOIForm.moodysRatings.Aaa' },
      { value: 'Aa1', label: 'components.IOIForm.moodysRatings.Aa1' },
      { value: 'Aa2', label: 'components.IOIForm.moodysRatings.Aa2' },
      { value: 'Aa3', label: 'components.IOIForm.moodysRatings.Aa3' },
      { value: 'A1', label: 'components.IOIForm.moodysRatings.A1' },
      { value: 'A2', label: 'components.IOIForm.moodysRatings.A2' },
      { value: 'A3', label: 'components.IOIForm.moodysRatings.A3' },
      { value: 'Baa1', label: 'components.IOIForm.moodysRatings.Baa1' },
      { value: 'Baa2', label: 'components.IOIForm.moodysRatings.Baa2' },
      { value: 'Baa3', label: 'components.IOIForm.moodysRatings.Baa3' },
      { value: 'Ba1', label: 'components.IOIForm.moodysRatings.Ba1' },
      { value: 'Ba2-', label: 'components.IOIForm.moodysRatings.Ba2' },
      { value: 'Ba3', label: 'components.IOIForm.moodysRatings.Ba3' },
      { value: 'B1', label: 'components.IOIForm.moodysRatings.B1' },
      { value: 'B2', label: 'components.IOIForm.moodysRatings.B2' },
      { value: 'B3', label: 'components.IOIForm.moodysRatings.B3' },
    ];
  },

  dayCountList(): any {
    return [
      { value: '1', label: 'act/act following (ICMA)' },
      { value: '2', label: '30/360 following (ICMA)' },
      { value: '3', label: 'act/360 following (ICMA)' },
      { value: '4', label: 'act/365 following (ICMA)' },
    ];
  },

  structureList(): any {
    return [
      { value: 'SC', label: 'components.IOIForm.structureSuppliers' },
      { value: 'BC', label: 'components.IOIForm.structureBuyers' },
      { value: 'PF', label: 'components.IOIForm.structureProject' },
    ];
  },

  hourList(): any {
    return [
      { value: '00', label: '00' },
      { value: '01', label: '01' },
      { value: '02', label: '02' },
      { value: '03', label: '03' },
      { value: '04', label: '04' },
      { value: '05', label: '05' },
      { value: '06', label: '06' },
      { value: '07', label: '07' },
      { value: '08', label: '08' },
      { value: '09', label: '09' },
      { value: '10', label: '10' },
      { value: '11', label: '11' },
      { value: '12', label: '12' },
      { value: '13', label: '13' },
      { value: '14', label: '14' },
      { value: '15', label: '15' },
      { value: '16', label: '16' },
      { value: '17', label: '17' },
      { value: '18', label: '18' },
      { value: '19', label: '19' },
      { value: '20', label: '20' },
      { value: '21', label: '21' },
      { value: '22', label: '22' },
      { value: '23', label: '23' },
    ];
  },

  minutesList(): any {
    return [
      { value: '00', label: '00' },
      { value: '15', label: '15' },
      { value: '30', label: '30' },
      { value: '45', label: '45' },
    ];
  },

  spreadList(countryPayload: any): any {
    if (countryPayload.countryCode === null) {
      return [];
    }
    if (countryPayload.countryCode.trim() === 'FR') {
      return [
        { value: 'OAT', label: 'OAT' }, // Foreign loans
        { value: 'IRS', label: 'IRS (VS 6M)' },
      ];
    }
    if (countryPayload.countryCode.trim() === 'BE') {
      return [
        { value: 'OLO', label: 'OLO / BGB' },
        { value: 'IRS', label: 'IRS (VS 6M)' },
      ];
    }
    return [{ value: 'IRS', label: 'IRS (VS 6M)' }];
  },

  ioiRequiredLists(): any {
    return {
      industryList: this.industrySectorList(),
      instrumentTypeList: this.instrumentTypeList(),
      frequencyList: this.frequencyList(),
      rankingGuaranteeList: this.rankingGuaranteeList(),
      indicativeTypeList: this.indicativeTypeList(),
      dayCountList: this.dayCountList(),
      ratings: this.ratings(),
      moodysRatings: this.moodysRatings(),
      structureList: this.structureList(),
      rankingTypeList: this.rankingTypeList(),
      bucketSizeList: this.bucketSizeList(),
      maturityBucketList: this.maturityBucketList(),
      hourList: this.hourList(),
      minutesList: this.minutesList(),
    };
  },
};

export function spreadList(countryCode: any): any {
  if (countryCode.trim() === 'FR') {
    return [
      { value: 'OAT', label: 'OAT' }, // Foreign loans
      { value: 'IRS', label: 'IRS (VS 6M)' },
    ];
  }
  if (countryCode.trim() === 'BE') {
    return [
      { value: 'OLO', label: 'OLO / BGB' },
      { value: 'IRS', label: 'IRS (VS 6M)' },
    ];
  }
  return [{ value: 'IRS', label: 'IRS (VS 6M)' }];
}
