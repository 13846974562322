'use strict';

import api from './index';

export default {
  getInterestRatePage(graphType: string) {
    return api.get(`/curve-snapshot/${graphType}`);
  },

  submitInterestRatePage(filePayload: any) {
    return api.post('/curve-snapshot', filePayload);
  },

  submitESTRInterestRatePage(filePayload: any) {
    return api.post('/curve-snapshot/estr', filePayload);
  },
};
